body {
  margin: 0;
  font-family: "Raleway", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  min-width: 650px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#navbar {
  font-size: 13pt;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-top: 1px solid rgb(54, 54, 54);
  background-color: rgb(49, 49, 49);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#navbar ul {
  margin: 0;
  padding: 0;
}

#navbar li {
  float: left;
  display: block;
  padding: 14px 25px;
  border-right: 1px solid rgb(163, 163, 163);
}

#navbar a {
  text-align: center;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

#navbar a:hover {
  color: rgb(87, 87, 255);
}

#footer {
  margin: 3%;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

#footer a {
  text-decoration: none;
  text-decoration-line: underline;
  color: black;
}

#footer a:hover {
  text-decoration: none;
  text-decoration-line: underline;
  color: rgb(87, 87, 255);
}

#home {
  font-weight: bold;
  border-right: 1px solid rgb(163, 163, 163);
}

.content {
  margin: 30px;
  background-color: white;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.3);

  padding: 20px;
  border-radius: 5px;
}

.content-title {
  margin: 0px 20px;
  border-bottom: 1px solid rgb(163, 163, 163);
}

.content-body {
  margin: 35px;
}

.content-body a {
  text-decoration: none;
  text-decoration-line: none;
  color: black;
}

.content-body a:hover {
  text-decoration: none;
  text-decoration-line: none;
  color: rgb(87, 87, 255);
}

#profile-picture {
  width: 175px;
  margin: 0;
}

#profile {
  text-align: center;
  margin: 30px;
  color: black;
}

.card-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: max-content;
  align-items: flex-start;
}

.resume-card {
  width: 100%;
  transition: all 0.2s ease-in-out;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
}

.resume-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.resume-card-text {
  float: left;
  width: 300px;
}

.position-detail-bullet {
  padding: 2px 0px 2px 0px;
}

.volunteer-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  width: 43%;
  transition: all 0.2s ease-in-out;
}

.volunteer-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.volunteer-card-text {
  float: left;
  width: 300px;
}

.volunteer-card:hover {
  transform: scale(1.015);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cert-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  width: 43%;
  min-height: 100px;
}

.cert-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.cert-card-text {
  float: left;
  width: 350px;
}

.description {
  align-self: end;
}

.card-header {
  color: black;
  font-size: large;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-sub {
  margin: 5px;
}

.card-sub.link {
  text-decoration: none;
}

.resume-card:hover {
  transform: scale(1.015);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.blog-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.blog-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
}

.blog-card-text {
  flex: 1;
  margin-left: 15px;
  min-width: 300px;
}

.blog-card h3 {
  margin: 0;
}

.blog-image {
  width: 300px;
  margin: 0 10 5 0;
  object-fit: cover;
  max-height: 180px;
}

.blog-date {
  margin-top: 5px;
}

.reading-card-section {
  margin: auto;
  width: 95%;
  justify-content: flex-start;
}

.reading-card {
  width: 240px;
  max-height: 300px;
  min-height: 300px;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.reading-card a {
  text-decoration: none;
  text-decoration-line: none;
}

.reading-card a:hover {
  text-decoration: none;
  text-decoration-line: none;
}

.reading-card-content {
  margin: auto;
}

.reading-card-text {
  margin-bottom: 10px;
  height: 50px;
}

.reading-card-image {
  margin: auto;
  width: 65%;
}

.reading-image {
  max-width: 100%;
  max-height: 240px;
}

.contact-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
}

.description-list {
  list-style: disc;
}

.company-logo {
  width: 300px;
}

.cert-logo {
  max-width: 110px;
}

.school-logo {
  max-width: 175px;
  float: right;
}

.volunteer-logo {
  max-width: 250px;
  float: right;
}

.education-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  width: 43%;
  transition: all 0.2s ease-in-out;
}

.education-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.education-card-text {
  float: left;
  width: 300px;
}

.education-card:hover {
  transform: scale(1.015);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#record-icon {
  width: 110px;
  height: 110px;
}

.skills-section {
  display: flex;
  flex-direction: column;
}

.skillbar {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  background: #eee;
  height: 35px;
}

.skillbar-bar {
  height: 35px;
  width: 100%;
  background: #6adcfa;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -ms-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color;
}

.skill-title {
  color: white;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  font-weight: bold;
  font-size: 13px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  display: block;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
}

.skill-level {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 11px;
  height: 35px;
  line-height: 35px;
}

#language {
  background: rgb(81, 93, 212);
}

#framework {
  background: rgb(65, 199, 177);
}

#tool {
  background: rgb(247, 67, 97);
}

#collaboration {
  background: rgb(213, 77, 255);
}

.expand-arrow {
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  clear: both;
}

.link-image {
  max-width: 200px;
  margin: 1em;
  border: gray solid 1px;
  border-radius: 3px;
}

.link-image:hover {
  transform: scale(1.015);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.badge-base__link {
  visibility: hidden;
}
